export const FORM_LAYOUT_CONTROLS_PRODUCT_DATA_SOURCE_ROOT_ID_KEY = "ProductDataSourceRootId";
export const FORM_LAYOUT_CONTROLS_PRODUCT_DATA_SOURCE_FIELD_ROOT_ID_KEY = "ProductDataSourceFieldRootId";
export const FORM_LAYOUT_CONTROLS_ORGANIZATION_DATA_SOURCE_ROOT_ID_KEY = "OrganizationDataSourceRootId";
export const FORM_LAYOUT_CONTROLS_ORGANIZATION_DATA_SOURCE_FIELD_ROOT_ID_KEY = "OrganizationDataSourceFieldRootId";
export const FORM_LAYOUT_CONTROLS_NAME_KEY = "Name";
export const FORM_LAYOUT_CONTROLS_TEXT_LABEL_KEY = "Label";
export const FORM_LAYOUT_CONTROLS_FORM_LAYOUT_ROOT_ID_KEY = "FormLayoutRootId";
export const FORM_LAYOUT_CONTROLS_LABEL_KEY = "Label";
export const FORM_LAYOUT_CONTROLS_CONTROL_TYPE_KEY = "ControlType";
export const FORM_LAYOUT_CONTROLS_DATA_TYPE_KEY = "DataType";
export const FORM_LAYOUT_CONTROLS_SETTINGS_JSON_KEY = "SettingsJson";
export const FORM_LAYOUT_CONTROLS_OPTIONS = "Options";
export const FORM_LAYOUT_CONTROLS_STYLES_JSON_KEY = "StylesJson";
export const FORM_LAYOUT_CONTROLS_TAB_POSITION_KEY = "TabPosition";
export const FORM_LAYOUT_CONTROLS_HEIGHT_KEY = "Height";
export const FORM_LAYOUT_CONTROLS_WIDTH_KEY = "Width";
export const FORM_LAYOUT_CONTROLS_X_POS = "XPos";
export const FORM_LAYOUT_CONTROLS_Y_POS = "YPos";
export const FORM_LAYOUT_CONTROLS_PUBLIC_URL = "ImageS3PublicUrl";
export const FORM_LAYOUT_CONTROLS_IS_READONLY = "IsReadOnly";
export const FORM_LAYOUT_CONTROLS_IS_RICH_TEXT = "IsRichText";
export const FORM_LAYOUT_CONTROLS_IS_IMMUTABLE = "IsImmutable";
export const ATTACHMENTS_FORM_LAYOUT_CONTROL_ROOTID = "AttachmentFormLayoutControlRootId";
export const FORM_LAYOUT_NAVIGATION_FORM_DEFAULT_SELECTED_ITEM = "DefaultSelectedFormItem";
export const FORM_LAYOUT_IMAGE_S3_KEY = "ImageS3Key";

export const FORM_LAYOUT_CONTROL_CHECK_BOX_PRODUCT_FIELD_DATASOURCE_ROOT_ID = "CheckBoxProductDataSourceFieldRootId";
export const FORM_LAYOUT_CONTROL_CHECK_BOX_ORGANIZATION_FIELD_DATASOURCE_ROOT_ID = "CheckBoxOrganizationDataSourceFieldRootId";

export const FORM_LAYOUT_CONTROLS_DEFAULT_VALUE = "DefaultValue";
export const FORM_LAYOUT_CONTROLS_TEMPORARY_AUTOMATIC_RESIZE_KEY = "automaticResize";
export const FORM_LAYOUT_CONTROLS_JUSTIFY_KEY = "justifyDirection";

export const FORM_LAYOUT_CONTROLS_MODEL_VALUE_KEY = "ModelValueKey";

export const FORM_LAYOUT_CONTROLS_TREE_DATA = "formLayoutControlsTreeData";

export function getFormLayoutControlsTreeData(treeData, headerText, collapsed) {
  if (!treeData || treeData.length === 0) {
    return [];
  }
  const res = treeData.reduce((acc, item) => {
    if (item.Children && item.Children.length > 0) {
      const treeContent = getFormLayoutControlsTreeData(item.Children, "", collapsed);
      acc.push({
        rootId: item.RootId,
        headerText: headerText || item.DisplayName,
        treeContent,
        isCollapsed: collapsed,
        isGroup: item?.isGroup ?? false,
      });
    } else {
      acc.push({
        rootId: item.RootId,
        headerText: headerText || item.DisplayName,
        treeContent: [],
        isCollapsed: collapsed,
        isGroup: item?.isGroup ?? false,
      });
    }

    return acc;
  }, []);
  if (res.length === 0) {
    return [];
  }
  return res;
}

export const FORM_LAYOUT_CONTROLS_STYLES_JSON_LABEL_STYLES_KEY = "LayoutControlLabelStyles";

export function getFormLayoutControlJustifyDirection(item) {
  return item?.[FORM_LAYOUT_CONTROLS_STYLES_JSON_KEY]?.[FORM_LAYOUT_CONTROLS_JUSTIFY_KEY] ?? "";
}

// form layout control settings
export const FORM_LAYOUT_CONTROLS_SETTINGS_READ_ONLY_KEY = "ControlReadOnly";
export const FORM_LAYOUT_CONTROLS_SETTINGS_PLACEHOLDER = "SettingsPlaceholder";
export const FORM_LAYOUT_CONTROLS_VIEWS_DATASOURCE_ROOT_ID_KEY = "ViewsDataSourceRootId";
export const FORM_LAYOUT_CONTROLS_FULL_WIDTH_KEY = "FullWidth";
export const FORM_LAYOUT_CONTROLS_FULL_HEIGHT_KEY = "FullHeight";
export const FORM_LAYOUT_CONTROL_DROP_LIST_DISPLAY_KEY = "DisplayField";
export const FORM_LAYOUT_CONTROL_DROP_LIST_VALUE_KEY = "ValueField";
export const FORM_LAYOUT_CONTROL_SHOW_DEFAULT_ORDER_NUMBER_KEY = "ShowDefaultOrderNumber";
export const FORM_LAYOUT_CONTROLS_USE_CONFIGURATION_DATA = "UseConfigurationData";
export const FORM_LAYOUT_CONTROLS_UPDATE_CONFIGURATION_DATA = "UpdateConfigurationData";
export const FORM_LAYOUT_CONTROLS_SELECTED_DATASOURCES_KEY = "SelectedDataSources"; // filled with rootIds of seleccted datasources
export const FORM_LAYOUT_CONTROLS_VIEWS_WIDGET_HAS_CHECKBOX = "ViewsWidgetHasCheckbox";
export const FORM_LAYOUT_CONTROLS_IS_LINKED_TO_ATTACHMENT = "IsLinkedToMessageAttachment";
export const FORM_LAYOUT_WIDGET_HAS_UPLOADER = "WidgetHasUploader";
export const FORM_LAYOUT_MESSAGE_WIDGET_HEADER = "MessageWidgetHeader";
export const FORM_LAYOUT_SERVICE_PROVIDER_WIDGET_HEADER = "ServiceProviderWidgetHeader";
export const FORM_LAYOUT_CONTROLS_IS_REQUIRED = "IsRequired";
export const FORM_LAYOUT_CONTROLS_ASSOCIATED_DATASOURCE = "AssociatedDataSource";
export const FORM_LAYOUT_MESSAGE_WIDGET_SUBJECT_IS_REQUIRED = "MessageWidgetSubjectIsRequired";
export const FORM_LAYOUT_MESSAGE_WIDGET_SEND_REPLIES_TO_IS_REQUIRED = "MessageWidgetSendRepliesToIsRequired";
export const FORM_LAYOUT_MESSAGE_WIDGET_MESSAGE_BODY_IS_REQUIRED = "MessageWidgetMessageBodyIsRequired";
export const FORM_LAYOUT_MESSAGE_WIDGET_IS_ATTACHMENTS_REQUIRED = "MessageWidgetIsAttachmentsRequired";
export const FORM_LAYOUT_CONTROLS_SET_TO_SETTLEMENT_DATE = "SetToSettlementDate";
export const FORM_LAYOUT_RADIO_DATA = "RadioData";
export const FORM_LAYOUT_CONTROL_TOOLTIP = "Tooltip";
export const FORM_LAYOUT_SELECTED_SHOW_CONTROL_NAME = "ShowControlName";
export const FORM_LAYOUT_SHOW_CONTROL_CHECK_RADIO_OPTIONS = "ShowControlCheckRadioOptions";
export const FORM_LAYOUT_SELECTED_SERVICE_DOCUMENTS = "SelectedServiceDocuments";
export const FORM_LAYOUT_SPLIT_PANES_WIDGET_ITEMS = "SplitPanesWidgetItems";

// Widgets
export const FORM_LAYOUT_CONTROLS_VIEWS_GRID_MIN_WIDTH = 100;
