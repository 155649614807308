<!--suppress JSValidateTypes -->
<template>
  <span ref="root" class="select-none">
    <span class="cursor-pointer" @click="handleOpen">
      <div ref="icon" class="ml-3 flex justify-items-end mt-0.5">
        <div>
          <template v-if="isUrlValid">
            <img class="inline-block h-8 w-8 rounded-full object-cover" :src="loggedInUser?.AvatarSignedUrl ?? ''" alt="Profile Picture" />
          </template>
          <template v-else>
            <AvatarImage :username="loggedInUserFirstName" :size="32" />
          </template>
        </div>
        <div style="color: #ffffff !important" id="account-dropdown-arrow" class="material-icons mt-0.5">arrow_drop_down</div>
      </div>
      <div ref="icon" class="ml-3" />
    </span>
  </span>
</template>

<script>
import { ref, inject, onMounted, onBeforeUnmount, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { openContextMenuAtTargetElement } from "@/utils/contextMenuHelper";

import AvatarImage from "@/components/uIElements/AvatarImage.vue";
import getOrdersGridLink from "@/utils/navigation/getOrdersGridLink";

export default {
  name: "UserDropdown",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AvatarImage,
  },
  setup(props) {
    const emitter = inject("emitter");

    const router = useRouter();
    const route = useRoute();

    const root = ref(null);
    const icon = ref(null);
    const isOpen = ref(false);

    // Vuex
    const store = useStore();
    // State
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    // Getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    // Mutations
    const setIsSearchDocumentSelected = (isSearchDocumentSelected) => store.commit("setIsSearchDocumentSelected", isSearchDocumentSelected);
    const clearAuditLog = () => store.commit("clearAuditLog");

    // Computed
    const isUrlValid = computed(() => loggedInUser.value?.AvatarSignedUrl !== "");
    const loggedInUserFirstName = computed(() => {
      let firstName = loggedInUser.value?.DisplayName?.split(" ")[0] ?? "";
      if (firstName.length > 0) {
        return `${firstName}`;
      }
      return "Welcome!";
    });

    const onSettingsPage = computed(() => router.currentRoute.value.fullPath.includes("/user-settings"));
    const validItems = computed(() => {
      return props.items?.reduce((acc, item) => {
        if (item.text === "Settings" && !onSettingsPage.value) {
          acc.push(item);
        } else if (item.text !== "Settings") {
          acc.push(item);
        }
        return acc;
      }, []);
    });

    const getContextMenuPos = ref({ top: 0, left: 0 });

    // Functions
    function logoutUser() {
      window.location.href = "/";
    }

    function openSettingsPage() {
      router.push({ path: "/user-settings" }).catch(() => {
        console.error("Unable to open user-settings");
      });
    }

    async function onRouteClick(item) {
      setIsSearchDocumentSelected(false);
      clearAuditLog();
      if (item?.link?.length > 0) {
        switch (item.link) {
          case "orders-grid":
            await router.push(getOrdersGridLink(getGlobalSelectedProductRootId.value)).catch(() => {
              console.error("Unable to open orders grid");
            });
            break;
          default:
            await router.push({ path: item.link }).catch(() => {
              console.error(`Unable to open ${item.link}`);
            });
            break;
        }
      }
    }

    function closeOnClickOff(e) {
      if (!root.value?.contains(e.target)) {
        isOpen.value = false;
      }
    }

    function handleItemSelected(item) {
      if (item?.text === "Logout") {
        logoutUser();
      } else if (item?.text === "Settings") {
        openSettingsPage();
      } else {
        onRouteClick(item);
      }
    }

    function handleOpen() {
      isOpen.value = !isOpen.value;
      openContextMenuAtTargetElement(getContextMenuPos.value?.left, getContextMenuPos.value?.top, validItems.value, handleItemSelected);
    }

    function getContextMenuPosition() {
      const rect = icon.value?.getBoundingClientRect();
      getContextMenuPos.value = { top: rect?.top, left: rect?.left + rect?.width - 60 };
    }

    onMounted(() => {
      emitter.on("logoutUser", logoutUser);
      document.addEventListener("click", closeOnClickOff);
      window.addEventListener("resize", () => {
        getContextMenuPosition();
      });
      nextTick(() => {
        getContextMenuPosition();
      });
    });

    onBeforeUnmount(() => {
      emitter.off("passwordChanged");
      emitter.off("logoutUser");
      document.removeEventListener("click", closeOnClickOff);
    });

    return {
      router,
      route,

      root,
      isOpen,
      icon,
      loggedInUser,

      isUrlValid,
      loggedInUserFirstName,
      getContextMenuPos,
      validItems,

      handleItemSelected,
      logoutUser,
      handleOpen,
      openSettingsPage,
      onRouteClick,
    };
  },
};
</script>
