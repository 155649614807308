import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userAccessSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userAccessSettings: [],
  },
  getters: {
    getUserAccessSettingByRootId: (state) => (rootId) => {
      return state.userAccessSettings.find((userAccessSetting) => Number(userAccessSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setUserAccessSettingData(state, userAccessSettings) {
      state.userAccessSettings = userAccessSettings ?? [];
    },
    _addUserAccessSetting(state, userAccessSetting) {
      const userAccessSettingsData = _.cloneDeep(state.userAccessSettings) ?? [];
      userAccessSettingsData.push(userAccessSetting);
      state.userAccessSettings = userAccessSettingsData ?? [];
    },
    _updateUserAccessSetting(state, updateUserAccessSetting) {
      const rootId = Number(updateUserAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let userAccessSettingsData = _.cloneDeep(state.userAccessSettings) ?? [];
        userAccessSettingsData = [...userAccessSettingsData.filter(userAccessSetting => Number(userAccessSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateUserAccessSetting)] ?? [];
        state.userAccessSettings = userAccessSettingsData;
      }
    },
    _removeUserAccessSetting(state, deleteUserAccessSetting) {
      const rootId = Number(deleteUserAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let userAccessSettingsData = _.cloneDeep(state.userAccessSettings) ?? [];
        userAccessSettingsData = [...userAccessSettingsData.filter(userAccessSetting => Number(userAccessSetting?.RootId ?? 0) !== rootId)] ?? [];
        state.userAccessSettings = userAccessSettingsData;
      }
    },
  },
  actions: {
    async getUserAccessSettings(context, { UserRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        UserRootId = Number(UserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${userAccessSettingsUrl}?userrootid=${UserRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setUserAccessSettingData", data);
        return data;
      }, "userAccessSettings - getUserAccessSettings");
    },
    async addUserAccessSetting(context, userAccessSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userAccessSettingsUrl, Payload: userAccessSetting });
        if (data?.RootId > 0) {
          userAccessSetting.RootId = data.RootId;
          context.commit("_addUserAccessSetting", data);
        }
        return data;
      }, "userAccessSettings - addUserAccessSetting");
    },
    async updateUserAccessSetting(context, userAccessSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${userAccessSettingsUrl}/${Number(userAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userAccessSetting});
        context.commit("_updateUserAccessSetting", data);
        return data;
      }, "userAccessSettings - updateUserAccessSetting");
    },
    async removeUserAccessSetting(context, userAccessSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${userAccessSettingsUrl}/${Number(userAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeUserAccessSetting", userAccessSetting);
        return data;
      }, "userAccessSettings - removeUserAccessSetting");
    },
  },
};
