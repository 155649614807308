<template>
  <div v-if="localShow" ref="contextMenuRef" class="fixed border custom-shadow rounded-sm z-top bg-white" :style="styles">
    <div
      v-for="(item, index) in localItems"
      :key="index"
      @click="(e) => handleItemSelected(e, item)"
      :class="item.enabled === false ? 'disabled-look' : 'cursor-pointer bg-darker'"
    >
      <div v-if="!item.separator" class="py-2 pr-2">
        <span :class="item.iconCss" class="px-2"></span>
        {{ item?.text ?? "" }}
      </div>
      <div v-else>
        <hr class="my-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, watch, onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";

const PAGE_BUFFER = 5;
const PIXELS_BELOW_CLICK = 10;
const MIN_TIME_SHOWN = 100;
export default {
  name: "ContextMenuReplacement",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    beforeOpen: {
      type: Function,
      default: () => {},
    },
    itemSelectedFunction: {
      type: Function,
      default: () => {},
    },
    targetElementTop: {
      type: Number,
      default: -1,
    },
    targetElementLeft: {
      type: Number,
      default: -1,
    },
  },
  emits: ["update:open", "update:close", "update:show", "itemSelected"],
  setup(props, { emit }) {
    const store = useStore();
    // Mutations
    const setTargetElementTop = (top) => store.commit("setTargetElementTop", top);
    const setTargetElementLeft = (left) => store.commit("setTargetElementLeft", left);

    const contextMenuRef = ref(null);
    const left = ref(0);
    const top = ref(0);
    const height = ref(0);
    const width = ref(0);
    const localShow = ref(false);
    const preventGlobalClick = ref(false);
    const localItems = ref([]);

    const show = computed(() => props.show);
    watch(show, (is) => {
      localShow.value = is;
    });
    watch(localShow, (is) => {
      if (is) {
        preventGlobalClick.value = true;
        setTimeout(() => {
          preventGlobalClick.value = false;
        }, MIN_TIME_SHOWN);
      }
      if (is !== props.show) {
        emit("update:show", is);
      }
    });
    const styles = computed(() => {
      return {
        left: props.targetElementLeft > -1 ? `${props.targetElementLeft}px` : `${left.value}px`,
        top: props.targetElementTop > -1 ? `${props.targetElementTop}px` : `${top.value}px`,
      };
    });

    // TODO: watch scroll and hide this
    // TODO: account for page size

    function getUpdatedValues() {
      if (contextMenuRef.value?.getBoundingClientRect?.()?.height !== height.value) {
        height.value = contextMenuRef.value?.getBoundingClientRect?.()?.height;
      }
      if (contextMenuRef.value?.getBoundingClientRect?.()?.width !== width.value) {
        width.value = contextMenuRef.value?.getBoundingClientRect?.()?.width;
      }
    }
    async function open(x, y, newItems) {
      if (localShow.value) {
        return;
      }
      await props.beforeOpen();
      localShow.value = true;
      localItems.value = _.cloneDeep(newItems);
      await nextTick();
      getUpdatedValues();
      const safeLeft = getSafeLeft(x);
      const safeTop = getSafeTop(y + PIXELS_BELOW_CLICK);
      left.value = safeLeft;
      top.value = safeTop;
    }
    function getSafeLeft(x) {
      const safeLeft = x + width.value > window.innerWidth + PAGE_BUFFER ? window.innerWidth - width.value - PAGE_BUFFER : x;
      return safeLeft;
    }
    function getSafeTop(y) {
      const safeTop = y + height.value > window.innerHeight + PAGE_BUFFER ? window.innerHeight - height.value - PAGE_BUFFER : y;
      return safeTop;
    }
    function hide() {
      localShow.value = false;
      // Reset target element, so it doesn't show up in the wrong place next time it opens
      setTargetElementTop(-1);
      setTargetElementLeft(-1);
      // Clear menu localItems, so they don't show up next time
      localItems.value?.splice(0, localItems.value.length);
    }
    function checkHide() {
      if (!preventGlobalClick.value && localShow.value) {
        hide();
      }
    }
    function handleItemSelected(e, item) {
      e.stopPropagation();
      if (item.enabled === false) {
        return;
      }
      emit("itemSelected", _.cloneDeep(item));
      props.itemSelectedFunction(_.cloneDeep(item));
      hide();
    }

    onMounted(() => {
      nextTick(() => {
        getUpdatedValues();
        emit("update:open", open);
        emit("update:close", hide);
        localShow.value = props.show;
        window.addEventListener("resize", hide);
        window.addEventListener("wheel", hide);
        window.addEventListener("click", checkHide);
        window.onscroll = hide;
      });
    });

    return {
      left,
      top,
      height,
      width,
      localItems,
      localShow,

      styles,

      open,

      contextMenuRef,

      handleItemSelected,
    };
  },
};
</script>

<style scoped>
.z-top {
  z-index: 1100002;
}
.bg-white {
  background-color: white;
  width: max-content; /* Fixes issue with width not resetting when closing and opening the context menu  */
}
.disabled-look {
  color: #999;
  cursor: default;
}
.custom-shadow {
  box-shadow: 2px 4px 6px 2px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.bg-darker:hover {
  background-color: #f5f5f5;
}
</style>
