import {ApiRequest, GetVerb, groupsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    groupTemplate: null,
    selectedGroupGroupAccessProductRootId: 0,
  },
  mutations: {
    setGroupTemplateData(state, groupTemplate) {
      state.groupTemplate = groupTemplate;
    },
    setSelectedGroupGroupAccessProductRootId(state, productRootId) {
      state.selectedGroupGroupAccessProductRootId = Number(productRootId ?? 0);
    },
  },
  actions: {
    async getGroupTemplate(context, params) {
      await timeOperation(async () => {
        if (Number(params?.OrganizationRootId ?? 0) > 0) {
          const formattedUrl = `${groupsUrl
            }?organizationrootid=${Number(params?.OrganizationRootId ?? 0)
            }&template=true&includemetadata=${params?.IncludeMetadata ?? true
            }&includemembership=${params?.IncludeMembership ?? false
            }&includeaccesssettings=${params?.IncludeAccessSettings ?? false}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setGroupTemplateData", data);
        } else {
          context.commit("setGroupTemplateData", null);
        }
      }, "groups - getGroupTemplate");
    },
  },
};
