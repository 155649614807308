<template>
  <div v-if="show" @dragenter.prevent @dragover.prevent @drop="handleDrop">
    <div class="fixed w-screen h-screen top-0 left-0" :class="`${onTopOfModal ? 'z-1001' : 'z-1000'} ${fadeBackground ? 'opacity-20 bg-primary' : ''}`"></div>
    <div
      :class="`${isModal ? 'modal-container' : 'message-container'} ${onTopOfModal ? 'z-1001' : 'z-1000'} ${fillScreen ? 'max-width-s max-height-s' : ''} ${
        expandToMaxSize ? 'min-width-s min-height-s' : ''
      } ${growWithContent ? 'grow-width' : ''}`"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundOverlay",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fadeBackground: {
      type: Boolean,
      default: false,
    },
    growWithContent: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    onTopOfModal: {
      type: Boolean,
      default: false,
    },
    fillScreen: {
      type: Boolean,
      default: false,
    },
    expandToMaxSize: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return {
      handleDrop,
    };
  },
};
</script>

<style scoped>
.z-1000 {
  z-index: 1000000;
}
.z-1001 {
  z-index: 1001;
}
.message-container {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: calc(0.5 * 32px);
  color: #34406b;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: calc(0.5 * 16px); /* less rounding */
  color: #34406b;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
}
.max-width-s {
  max-width: 95vw;
}
.max-height-s {
  max-height: 95vh;
}
.min-width-s {
  min-width: 95vw;
}
.min-height-s {
  min-height: 95vh;
}
.grow-width {
  display: block;
  width: max-content;
  max-width: 95vw;
  box-sizing: border-box;
}
</style>
