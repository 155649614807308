import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userConfigSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userConfigSettings: [],
  },
  getters: {
    getUserConfigSettingByRootId: (state) => (rootId) => {
      return state.userConfigSettings.find((userConfigSetting) => Number(userConfigSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setUserConfigSettingData(state, userConfigSettings) {
      state.userConfigSettings = userConfigSettings ?? [];
    },
    _addUserConfigSetting(state, userConfigSetting) {
      const userConfigSettingsData = _.cloneDeep(state.userConfigSettings) ?? [];
      userConfigSettingsData.push(userConfigSetting);
      state.userConfigSettings = userConfigSettingsData ?? [];
    },
    _updateUserConfigSetting(state, updateUserConfigSetting) {
      const rootId = Number(updateUserConfigSetting?.RootId ?? 0);
      if (rootId > 0) {
        let userConfigSettingsData = _.cloneDeep(state.userConfigSettings) ?? [];
        userConfigSettingsData = [...userConfigSettingsData.filter(userConfigSetting => Number(userConfigSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateUserConfigSetting)] ?? [];
        state.userConfigSettings = userConfigSettingsData;
      }
    },
    _removeUserConfigSetting(state, deleteUserConfigSetting) {
      const rootId = Number(deleteUserConfigSetting?.RootId ?? 0);
      if (rootId > 0) {
        let userConfigSettingsData = _.cloneDeep(state.userConfigSettings) ?? [];
        userConfigSettingsData = [...userConfigSettingsData.filter(userConfigSetting => Number(userConfigSetting?.RootId ?? 0) !== rootId)] ?? [];
        state.userConfigSettings = userConfigSettingsData;
      }
    },
  },
  actions: {
    async getUserConfigSettings(context, { UserRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        UserRootId = Number(UserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${userConfigSettingsUrl}?userrootid=${UserRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setUserConfigSettingData", data);
        return data;
      }, "userConfigSettings - getUserConfigSettings");
    },
    async addUserConfigSetting(context, userConfigSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userConfigSettingsUrl, Payload: userConfigSetting });
        if (data?.RootId > 0) {
          userConfigSetting.RootId = data.RootId;
          context.commit("_addUserConfigSetting", data);
        }
        return data;
      }, "userConfigSettings - addUserConfigSetting");
    },
    async updateUserConfigSetting(context, userConfigSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${userConfigSettingsUrl}/${Number(userConfigSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userConfigSetting});
        context.commit("_updateUserConfigSetting", data);
        return data;
      }, "userConfigSettings - updateUserConfigSetting");
    },
    async removeUserConfigSetting(context, userConfigSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${userConfigSettingsUrl}/${Number(userConfigSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeUserConfigSetting", userConfigSetting);
        return data;
      }, "userConfigSettings - removeUserConfigSetting");
    },
  },
};
