import { ApiRequest, GetVerb, PostVerb, orderDocumentsUrl, PutVerb } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {OrderDocumentRecordType} from "@/lib/settings";
import _ from "lodash";
import utcToTimezone from "@/utils/utcToTimezone";
import orderTimezone from "@/utils/orderTimezone";
import {getGridData} from "@/store/searchResults";

export default {
  state: {
    orderDocuments: [],
    orderDocument: {},
    orderDocumentRevisions: [],
    selectedDocumentPlaceOrderOrderNumber: {},
    isOrderDocumentsEditorInFullscreen: false,
    cachedOrderDocumentProductRootId: -1,
    cachedOrderDocumentOrderRootId: -1,
  },
  getters: {},
  mutations: {
    setOrderDocumentData(state, params) {
      let orderDocumentData = params?.Data ?? [];
      const showInternalWriteUp = params?.ShowInternalWriteUp ?? false;
      let orderDocuments = [];
      if (showInternalWriteUp) {
        orderDocuments = orderDocumentData;
      } else {
        orderDocuments =
          orderDocumentData.filter((orderDocument) => {
            return orderDocument.Name.toLowerCase() !== "internal write-up";
          }) ?? [];
      }
      state.orderDocuments = orderDocuments?.sort((od1, od2) => (od1.Name > od2.Name ? 1 : -1));
    },
    clearOrderDocumentData(state) {
      state.orderDocuments = [];
    },
    setOrderDocumentWithRevisionData(state, orderDocument) {
      state.orderDocument = orderDocument ?? {};
      let orderDocumentRevisions = [];
      if (orderDocument != null) {
        if (orderDocument.CreatedDateTime && String(orderDocument.CreatedDateTime).includes("T") && !String(orderDocument.CreatedDateTime).includes("Z")) {
          orderDocument.CreatedDateTime = `${orderDocument.CreatedDateTime}.000Z`;
        }
        let historicalRecords = orderDocument.HistoricalRecords;
        orderDocument.HistoricalRecords = [];
        orderDocumentRevisions.push(_.cloneDeep(orderDocument));
        if (historicalRecords?.length > 0) {
          historicalRecords.forEach((historicalRecord) => {
            if (historicalRecord.CreatedDateTime && String(historicalRecord.CreatedDateTime).includes("T") && !String(historicalRecord.CreatedDateTime).includes("Z")) {
              historicalRecord.CreatedDateTime = `${historicalRecord.CreatedDateTime}.000Z`;
            }
            orderDocumentRevisions.push(_.cloneDeep(historicalRecord));
          });
        }
      }
      state.orderDocumentRevisions = orderDocumentRevisions;
    },
    clearOrderDocument(state) {
      state.orderDocument = {};
    },
    setOrderDocument(state, orderDocument) {
      let newOrderDocument = orderDocument ?? {};
      if (newOrderDocument?.RootId > 0) {
        newOrderDocument.CreatedDateTime = newOrderDocument.CreatedDateTime ? utcToTimezone(newOrderDocument.CreatedDateTime, orderTimezone(null)) ?? "" : null;
        if (newOrderDocument.HistoricalRecords?.length > 0) {
          newOrderDocument.HistoricalRecords.forEach((orderDocument) => {
            orderDocument.CreatedDateTime = orderDocument.CreatedDateTime ? utcToTimezone(orderDocument.CreatedDateTime, orderTimezone(null)) ?? "" : null;
          });
        }
      }
      state.orderDocument = newOrderDocument;
    },
    setSelectedOrderDocument(state, orderDocument) {
      state.selectedDocumentPlaceOrderOrderNumber = orderDocument ?? {};
    },
    setIsOrderDocumentsEditorInFullscreen(state, isOrderDocumentsEditorInFullscreen) {
      state.isOrderDocumentsEditorInFullscreen = isOrderDocumentsEditorInFullscreen;
    },
    setCachedOrderDocumentProductRootId(state, cachedOrderDocumentProductRootId) {
      state.cachedOrderDocumentProductRootId = Number(cachedOrderDocumentProductRootId ?? -1);
    },
    setCachedOrderDocumentOrderRootId(state, cachedOrderDocumentOrderRootId) {
      state.cachedOrderDocumentOrderRootId = Number(cachedOrderDocumentOrderRootId ?? -1);
    },
  },
  actions: {
    async getOrderDocumentsAdvancedSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      UserRootId,
      SearchRootId,
      PreviewParameters,
      IncludeMetadata,
      IncludeDebugData,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${orderDocumentsUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&includedebugdata=${IncludeDebugData}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: OrderDocumentRecordType, Data });
      }, "orderDocuments - getOrderDocumentsAdvancedSearchData");
    },
    async getOrderDocumentData(context, {
      OrderRootId,
      ProductRootId,
      UseCache,
      ShowInternalWriteUp,
    }) {
      await timeOperation(async () => {
        OrderRootId = Number(OrderRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        UseCache = UseCache ?? false;
        ShowInternalWriteUp = ShowInternalWriteUp ?? false;
        if ((OrderRootId > 0) && (ProductRootId > 0)) {
          const cachedOrderDocumentProductRootId = Number(context.state.cachedOrderDocumentProductRootId ?? 0);
          const cachedOrderDocumentOrderRootId = Number(context.state.cachedOrderDocumentOrderRootId ?? 0);
          const dirtyCache = (cachedOrderDocumentProductRootId !== ProductRootId) || (cachedOrderDocumentOrderRootId !== OrderRootId);
          if (!UseCache || dirtyCache) {
            context.commit("setCachedOrderDocumentOrderRootId", OrderRootId);
            context.commit("setCachedOrderDocumentProductRootId", ProductRootId);
            const formattedUrl = `${orderDocumentsUrl}?orderrootid=${OrderRootId}&productrootid=${ProductRootId}&includemetadata=true`;
            const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
            context.commit("setOrderDocumentData", {
              Data: data,
              ShowInternalWriteUp: ShowInternalWriteUp,
            });
          }
        } else {
          console.error("OrderRootId is not valid");
          context.commit("setCachedOrderDocumentOrderRootId", -1);
          context.commit("clearOrderDocumentData");
        }
      }, "orders - getOrderDocumentData");
    },
    async getOrderDocument(context, {
      OrderRootId,
      ProductRootId,
      TemplateName
    }) {
      await timeOperation(async () => {
        OrderRootId = Number(OrderRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        TemplateName = String(TemplateName ?? "");
        if ((OrderRootId > 0) && (ProductRootId > 0) && (TemplateName.length > 0)) {
          const formattedUrl = `${orderDocumentsUrl}?orderrootid=${OrderRootId}&productrootid=${ProductRootId}&templatename=${TemplateName}&includemetadata=true&includehistory=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrderDocument", data);
        } else {
          console.error("invalid OrderRootId or TemplateName");
          context.commit("clearOrderDocument");
        }
      }, "orders - getOrderDocument");
    },
    async addOrderDocument(context, orderDocument) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: orderDocumentsUrl, Payload: orderDocument });
      }, "orderDocuments - addOrderDocument");
    },
    async updateOrderDocument(context, orderDocument) {
      await timeOperation(async () => {
        const formattedUrl = `${orderDocumentsUrl}/${Number(orderDocument?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: orderDocument });
        if (!data?.Result) {
          console.error(data?.ErrorMessage ?? `Unable to save order document ${data ?? ""}`);
        }
        context.commit("setOrderDocument", data);
      }, "orders - updateOrderDocument");
    },
  },
};
