<template>
  <div class="flex pb-0.5 pr-2">
    <span>
      <span v-if="isAuthenticated">
        <span v-if="inPlatformAdminMode || inAdminMode">
          <a>
            <UserDropDown title="Settings" :items="isAdmin ? servicesAdminPortal : servicesUser" />
          </a>
        </span>
        <span v-else>
          <a>
            <UserDropDown title="Settings" :items="isAdmin ? servicesAdminHome : servicesUser" />
          </a>
        </span>
      </span>
      <span v-else>
        <router-link to="/login"> Login </router-link>
        <span>
          <a class="user-icon"><span class="material-icons">account_circle</span></a>
        </span>
      </span>
    </span>
    <div class="ml-2 mt-0.5 pt-0.5">
      <FeedbackButton innerCssClass="w-6 h-6 pt-1" outerCssClass="cursor-pointer" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import UserDropDown from "./UserDropdown";
import FeedbackButton from "@/components/uIElements/FeedbackButton.vue";

export default {
  name: "UserLoginStatus",
  components: {
    UserDropDown,
    FeedbackButton,
  },
  setup() {
    // Vuex
    const store = useStore();
    // State
    const currentHomeRoute = computed(() => store.state.auth.currentHomeRoute);
    const isAdmin = computed(() => store.state.auth.isAdmin);
    const inPlatformAdminMode = computed(() => store.state.auth.inPlatformAdminMode);
    const inAdminMode = computed(() => store.state.auth.inAdminMode);
    // Getters
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    // Computed
    const servicesAdminHome = computed(() => [
      {
        text: "Home",
        target: ".e-content",
        link: currentHomeRoute.value.includes("/links") ? "/links" : "orders-grid",
      },
      {
        text: "Admin",
        target: ".e-content",
        link: "/admin/documents/categories",
      },
      {
        text: "Settings",
        target: ".e-content",
        link: "",
      },
      {
        text: "Logout",
        target: ".e-content",
        link: "logout",
      },
    ]);
    const servicesAdminPortal = computed(() => [
      {
        text: "Home",
        target: ".e-content",
        link: currentHomeRoute.value.includes("/links") ? "/links" : "orders-grid",
      },
      {
        text: "Settings",
        target: ".e-content",
        link: "",
      },
      {
        text: "Logout",
        target: ".e-content",
        link: "logout",
      },
    ]);
    const servicesUser = computed(() => [
      {
        text: "Home",
        target: ".e-content",
        link: currentHomeRoute.value.includes("/links") ? "/links" : "orders-grid",
      },
      {
        text: "Settings",
        target: ".e-content",
        link: "",
      },
      {
        text: "Logout",
        target: ".e-content",
        link: "logout",
      },
    ]);

    return {
      isAdmin,
      inPlatformAdminMode,
      inAdminMode,
      isAuthenticated,
      servicesAdminHome,
      servicesAdminPortal,
      servicesUser,
    };
  },
};
</script>
