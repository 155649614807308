<template>
  <div class="wrapper shift select-none">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.shift {
  position: relative;
  top: -42px;
  left: 15px;
}
.wrapper {
  width: 40px;
  height: 15px;
  transform: translate(-50%, -50%);
}

@keyframes circle {
  /* keep this constant */
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50%;
    transform: scaleX(1.7);
  }
  /* how big the ball stretches */
  40% {
    height: 4px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  /* how high the ball bounces (more px means less bounce up to 60px) */
  100% {
    top: 50px;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.4s;
}
/* rgba(0,0,0,.5) */
.shadow {
  width: 4px;
  height: 2px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 62px;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleY(1.5);
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleY(1);
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleY(0.5);
    transform: scaleX(0.5);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
</style>
