import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, searchGroupsUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  actions: {
    async getProductSearchGroupsForProduct(context, { ProductRootId, SearchLocationRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&organizationrootid=0&userrootid=0&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        return data;
      }, "searchGroups - getProductSearchGroupsForProduct");
    },
    async getOrganizationSearchGroupsForProduct(context, { ProductRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=0&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        return data;
      }, "searchGroups - getOrganizationSearchGroupsForProduct");
    },
    async getUserSearchGroupsForProduct(context, { ProductRootId, UserRootId, OrganizationRootId, SearchLocationRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        SearchLocationRootId = Number(SearchLocationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchlocationrootid=${SearchLocationRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        return data;
      }, "searchGroups - getUserSearchGroupsForProduct");
    },
    // async getSearchGroupTemplate(context, { ProductRootId, ProductDataSourceRootId }) {
    //   await timeOperation(async () => {
    //     context.commit("setCachedSearchGroupTemplateProductRootId", ProductRootId);
    //     context.commit("setCachedSearchGroupTemplateProductDataSourceRootId", ProductDataSourceRootId);
    //     const formattedUrl = `${searchGroupsUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&template=true`;
    //     const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
    //     context.commit("setSearchGroupTemplateData", data);
    //   }, "searchGroups - getSearchGroupTemplate");
    // },
    async addSearchGroup(context, searchGroup) {
      return timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: searchGroupsUrl, Payload: searchGroup });
      }, "searchGroups - addSearchGroup");
    },
    async updateSearchGroup(context, searchGroup) {
      await timeOperation(async () => {
        const formattedUrl = `${searchGroupsUrl}/${Number(searchGroup?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: searchGroup });
      }, "searchGroups - updateSearchGroup");
    },
    async removeSearchGroup(context, searchGroup) {
      await timeOperation(async () => {
        const formattedUrl = `${searchGroupsUrl}/${Number(searchGroup?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "searchGroups - removeSearchGroup");
    },
  },
};
