import { ApiRequest, GetVerb, PostVerb, workflowRulesUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { WorkflowRulesRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  actions: {
    async getWorkflowRulesAdvancedSearchData(
      context,
      { ProductRootId, OrganizationRootId, UserRootId, SearchRootId, PreviewParameters, IncludeMetadata, IncludeDebugData, NoCache }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${workflowRulesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchrootid=${SearchRootId}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&includemetadata=${IncludeMetadata}&includedebugdata=${IncludeDebugData}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: WorkflowRulesRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: WorkflowRulesRecordType, Data });
      }, "workflowRules - getWorkflowRulesAdvancedSearchData");
    },
    async addWorkflowRule(context, workflowRule) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: workflowRulesUrl, Payload: workflowRule });
      }, "workflowRules - addWorkflowRule");
    },
  },
};
