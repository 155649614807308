import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  ordersUrl,
  placeOrdersUrl,
  ordersTransactionTypeUrl,
  orderWriteUpsUrl,
  orderInternalWriteUpsUrl,
  validateNamesUrl,
  reportsUrl,
} from "@/lib/api";

import {
  GENERAL_FORM_KEY,
  SCHEDULE_B1_FORM_KEY,
  SCHEDULE_B2_FORM_KEY,
  SCHEDULE_A_POLICIES_FORM_KEY,
  SCHEDULE_A_LEGAL_DESCRIPTIONS_FORM_KEY,
  SCHEDULE_A_PIDS_FORM_KEY,
  SCHEDULE_A_ADDRESSES_FORM_KEY,
  SCHEDULE_A_EFFECTIVE_DATES_FORM_KEY,
} from "@/utils/types/internalWriteUp/internalWriteUp.js";
import { BranchOrdersReport, OrdersRecordType, OrdersReport } from "@/lib/settings";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { getGridData } from "@/store/searchResults.js";

export default {
  state: {
    basicOrder: {},
    orderContacts: [],
    orderWriteUp: {},
    selectedOrderWriteUp: {},
    orderInternalWriteUps: [],
    orderDS1s: [],
    staticTemplate: {},
    auditLogs: [],
    openOrderTabs: [],
    orderNumbers: [],
    orderNumberSearchData: [],
    orderReportMonthlyData: [],
    orderReportBranchData: [],
    selectedOrderRootId: 0,
    selectedOrderSearchRootId: 0,
    selectedOrderSearchName: "",
    orderPageSettingSearchRootId: 0,
    quickSearchOrderRootId: 0,
    quickSearchOrderNumber: "",
    currentFolderLabelViewType: "Property Address",
    currentOrdersViewType: "folders",
    currentOrderSearchInput: "",
    currentSearchOrderNumber: "",
    folderViewLabelTypes: [
      { Id: 1, Name: "Property Address", isActive: true },
      { Id: 2, Name: "Buyer/Borrower", isActive: false },
      { Id: 3, Name: "Seller/Owner", isActive: false },
      { Id: 4, Name: "Member Order Number", isActive: false },
      { Id: 5, Name: "Order Number", isActive: false },
    ],
    orderTransactionTypes: [],
    orderExaminationWorksheetRevisions: [],
    isSearchDocumentSelected: false,
    isOrderEditorInFullscreen: false,
    doesSearchPackageNeedToBeRecreated: false,
    isLeftScreenVisible: true,
    showFolderViewPageControls: false,
    isOrderDataEditorInFullscreen: false,
    isOrderTemplatesEditorInFullscreen: false,
    isOrderAppletsEditorInFullscreen: false,
    isOrderCommunicationEditorInFullscreen: false,
    cachedCompleteOrderProductRootId: -1,
    cachedCompleteOrderOrderRootId: -1,
    cachedSingleOrderRootId: -1,
    cachedSingleOrderResultsData: {},
  },
  getters: {
    getDefaultOrderColumns() {
      let defaultColumns = [];
      defaultColumns.push({
        field: "Open",
        headerText: "Open",
        visible: true,
        showInColumnChooser: false,
        width: 60,
        commands: [
          {
            type: "Edit",
            buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
          },
        ],
      });
      defaultColumns.push({
        field: "OrderNumber",
        headerText: "Order Number",
        isPrimaryKey: false,
        visible: true,
        showInColumnChooser: true,
        textAlign: "Left",
      });
      defaultColumns.push({
        field: "ExternalOrderNumber",
        headerText: "External Order Number",
        isPrimaryKey: false,
        visible: true,
        showInColumnChooser: true,
        textAlign: "Left",
      });
      defaultColumns.push({
        field: "PropertyAddress",
        headerText: "Property Address",
        isPrimaryKey: false,
        visible: true,
        showInColumnChooser: true,
        textAlign: "Left",
      });
      defaultColumns.push({
        field: "Buyer",
        headerText: "Buyer",
        isPrimaryKey: false,
        visible: true,
        showInColumnChooser: true,
        textAlign: "Left",
      });
      defaultColumns.push({
        field: "Seller",
        headerText: "Seller",
        isPrimaryKey: false,
        visible: true,
        showInColumnChooser: true,
        textAlign: "Left",
      });
      return defaultColumns;
    },
    isOrderLoaded: (state) => {
      return state.selectedOrderRootId > 0;
    },
    getOrderWriteUp: (state) => {
      return state.orderWriteUp ?? {};
    },
    doesOrderNumberExist: (state) => {
      return state.orderNumberSearchData?.length > 0;
    },
  },
  mutations: {
    setQuickSearchOrderNumber(state, orderNumber) {
      state.quickSearchOrderNumber = orderNumber ?? "";
    },
    setQuickSearchOrderRootId(state, orderRootId) {
      state.quickSearchOrderRootId = orderRootId ?? 0;
    },
    setIsOrderEditorInFullscreen(state, isOrderEditorInFullscreen) {
      state.isOrderEditorInFullscreen = isOrderEditorInFullscreen;
    },
    setIsOrderDataEditorInFullscreen(state, isOrderDataEditorInFullscreen) {
      state.isOrderDataEditorInFullscreen = isOrderDataEditorInFullscreen;
    },
    setIsOrderTemplatesEditorInFullscreen(state, isOrderTemplatesEditorInFullscreen) {
      state.isOrderTemplatesEditorInFullscreen = isOrderTemplatesEditorInFullscreen;
    },
    setIsOrderAppletsEditorInFullscreen(state, isOrderAppletsEditorInFullscreen) {
      state.isOrderAppletsEditorInFullscreen = isOrderAppletsEditorInFullscreen;
    },
    setIsOrderCommunicationEditorInFullscreen(state, isOrderCommunicationEditorInFullscreen) {
      state.isOrderCommunicationEditorInFullscreen = isOrderCommunicationEditorInFullscreen;
    },
    setIsLeftScreenVisible(state, isLeftScreenVisible) {
      state.isLeftScreenVisible = isLeftScreenVisible;
    },
    setDoesSearchPackageNeedToBeRecreated(state, doesSearchPackageNeedToBeRecreated) {
      state.doesSearchPackageNeedToBeRecreated = doesSearchPackageNeedToBeRecreated;
    },
    setSelectedOrderSearchRootId(state, searchRootId) {
      state.selectedOrderSearchRootId = Number(searchRootId ?? 0);
    },
    setSelectedOrderSearchName(state, searchName) {
      state.selectedOrderSearchName = String(searchName ?? "");
    },
    setOrderPageSettingSearchRootId(state, searchRootId) {
      state.orderPageSettingSearchRootId = Number(searchRootId ?? 0);
    },
    addOpenOrderTab(state, tab) {
      let containsTab = state.openOrderTabs.some((obj) => {
        return obj.FormattedSearchName === tab.FormattedSearchName;
      });
      if (!containsTab) {
        state.openOrderTabs.push(tab);
      }
    },
    removeOpenOrderTab(state, tab) {
      let indexToRemove = state.openOrderTabs.findIndex((obj) => {
        return obj.FormattedSearchName === tab.FormattedSearchName;
      });
      if (indexToRemove > -1) {
        state.openOrderTabs.splice(indexToRemove, 1);
      }
    },
    updateOpenOrderTabs(state, tabs) {
      state.openOrderTabs = tabs;
    },
    setShowFolderViewControls(state, showFolderViewPageControls) {
      state.showFolderViewPageControls = showFolderViewPageControls;
    },
    setCachedCompleteOrderProductRootId(state, cachedCompleteOrderProductRootId) {
      state.cachedCompleteOrderProductRootId = Number(cachedCompleteOrderProductRootId ?? -1);
    },
    setCachedCompleteOrderOrderRootId(state, cachedCompleteOrderOrderRootId) {
      state.cachedCompleteOrderOrderRootId = Number(cachedCompleteOrderOrderRootId ?? -1);
    },
    setCachedSingleOrderRootId(state, cachedSingleOrderRootId) {
      state.cachedSingleOrderRootId = Number(cachedSingleOrderRootId ?? -1);
    },
    setCachedSingleOrderResultsData(state, cachedSingleOrderResultsData) {
      state.cachedSingleOrderResultsData = cachedSingleOrderResultsData ?? {};
    },
    setBasicOrderData(state, basicOrderData) {
      state.basicOrder = basicOrderData ?? {};
    },
    clearBasicOrderData(state) {
      state.basicOrder = {};
    },
    setOrderContactsData(state, orderContacts) {
      state.orderContacts = orderContacts ?? [];
    },
    clearOrderContactsData(state) {
      state.orderContacts = [];
    },
    setOrderTemplateData(state, order) {
      let orderExaminationWorksheetRevisions = [];
      if (order != null) {
        if (order.OrderWriteUp != null) {
          let revisions = [];
          let newestId = order.OrderWriteUp.Id;
          if (order.OrderWriteUp.CreatedDateTime && String(order.OrderWriteUp.CreatedDateTime).includes("T") && !String(order.OrderWriteUp.CreatedDateTime).includes("Z")) {
            order.OrderWriteUp.CreatedDateTime = `${order.OrderWriteUp.CreatedDateTime}.000Z`;
          }
          revisions.push({
            Id: order.OrderWriteUp.Id,
            AttachmentRootId: order.OrderWriteUp.AttachmentRootId ?? 0,
            Type: "",
            RecordType: "writeup",
            Revision: order.OrderWriteUp.Revision,
            ParentRevision: order.OrderWriteUp.ParentRevision,
            BaseVersionLabel: order.OrderWriteUp.BaseVersionLabel,
            VersionLabel: order.OrderWriteUp.VersionLabel,
            Submitted: order.OrderWriteUp.Submitted ? "Yes" : "",
            SubmittedCheckMark: order.OrderWriteUp.Submitted ? String.fromCharCode(0x2713) : "",
            CreatedDateTime: new Date(order.OrderWriteUp.CreatedDateTime),
          });
          if (order.OrderWriteUp.HistoricalRecords?.length > 0) {
            order.OrderWriteUp.HistoricalRecords.forEach((historicalRecord) => {
              if (historicalRecord.Id > newestId) {
                newestId = historicalRecord.Id;
              }
              if (historicalRecord.CreatedDateTime && String(historicalRecord.CreatedDateTime).includes("T") && !String(historicalRecord.CreatedDateTime).includes("Z")) {
                historicalRecord.CreatedDateTime = `${historicalRecord.CreatedDateTime}.000Z`;
              }
              revisions.push({
                Id: historicalRecord.Id,
                AttachmentRootId: historicalRecord.AttachmentRootId ?? 0,
                Type: "",
                RecordType: "writeup",
                Revision: historicalRecord.Revision,
                ParentRevision: historicalRecord.ParentRevision,
                BaseVersionLabel: historicalRecord.BaseVersionLabel,
                VersionLabel: historicalRecord.VersionLabel,
                Submitted: historicalRecord.Submitted ? "Yes" : "",
                SubmittedCheckMark: historicalRecord.Submitted ? String.fromCharCode(0x2713) : "",
                CreatedDateTime: new Date(historicalRecord.CreatedDateTime),
              });
            });
          }
          orderExaminationWorksheetRevisions.push({
            Id: newestId,
            AttachmentRootId: 0,
            Type: "Write-Up",
            RecordType: "writeup",
            Revision: "",
            ParentRevision: "",
            CreatedDateTime: "",
            BaseVersionLabel: "",
            VersionLabel: "",
            Submitted: "",
            SubmittedCheckMark: "",
            Revisions: revisions,
          });
        }
        if (order.OrderScheduleA != null) {
          let revisions = [];
          let newestId = order.OrderScheduleA.Id;
          if (order.OrderScheduleA.CreatedDateTime && String(order.OrderScheduleA.CreatedDateTime).includes("T") && !String(order.OrderScheduleA.CreatedDateTime).includes("Z")) {
            order.OrderScheduleA.CreatedDateTime = `${order.OrderScheduleA.CreatedDateTime}.000Z`;
          }
          revisions.push({
            Id: order.OrderScheduleA.Id,
            AttachmentRootId: 0,
            Type: "",
            RecordType: "schedulea",
            Revision: order.OrderScheduleA.Revision,
            ParentRevision: order.OrderScheduleA.ParentRevision,
            BaseVersionLabel: order.OrderScheduleA.BaseVersionLabel,
            VersionLabel: order.OrderScheduleA.VersionLabel,
            Submitted: "",
            SubmittedCheckMark: "",
            CreatedDateTime: new Date(order.OrderScheduleA.CreatedDateTime),
          });
          if (order.OrderScheduleA.HistoricalRecords?.length > 0) {
            order.OrderScheduleA.HistoricalRecords.forEach((historicalRecord) => {
              if (historicalRecord.Id > newestId) {
                newestId = historicalRecord.Id;
              }
              if (historicalRecord.CreatedDateTime && String(historicalRecord.CreatedDateTime).includes("T") && !String(historicalRecord.CreatedDateTime).includes("Z")) {
                historicalRecord.CreatedDateTime = `${historicalRecord.CreatedDateTime}.000Z`;
              }
              revisions.push({
                Id: historicalRecord.Id,
                AttachmentRootId: 0,
                Type: "",
                RecordType: "schedulea",
                Revision: historicalRecord.Revision,
                ParentRevision: historicalRecord.ParentRevision,
                BaseVersionLabel: historicalRecord.BaseVersionLabel,
                VersionLabel: historicalRecord.VersionLabel,
                Submitted: "",
                SubmittedCheckMark: "",
                CreatedDateTime: new Date(historicalRecord.CreatedDateTime),
              });
            });
          }
          orderExaminationWorksheetRevisions.push({
            Id: newestId,
            AttachmentRootId: 0,
            Type: "Schedule-A",
            RecordType: "schedulea",
            Revision: "",
            ParentRevision: "",
            CreatedDateTime: "",
            BaseVersionLabel: "",
            VersionLabel: "",
            Submitted: "",
            SubmittedCheckMark: "",
            Revisions: revisions,
          });
        }
        if (order.OrderScheduleB1 != null) {
          let revisions = [];
          let newestId = order.OrderScheduleB1.Id;
          if (
            order.OrderScheduleB1.CreatedDateTime &&
            String(order.OrderScheduleB1.CreatedDateTime).includes("T") &&
            !String(order.OrderScheduleB1.CreatedDateTime).includes("Z")
          ) {
            order.OrderScheduleB1.CreatedDateTime = `${order.OrderScheduleB1.CreatedDateTime}.000Z`;
          }
          revisions.push({
            Id: order.OrderScheduleB1.Id,
            AttachmentRootId: 0,
            Type: "",
            RecordType: "scheduleb1",
            Revision: order.OrderScheduleB1.Revision,
            ParentRevision: order.OrderScheduleB1.ParentRevision,
            BaseVersionLabel: order.OrderScheduleB1.BaseVersionLabel,
            VersionLabel: order.OrderScheduleB1.VersionLabel,
            Submitted: "",
            SubmittedCheckMark: "",
            CreatedDateTime: new Date(order.OrderScheduleB1.CreatedDateTime),
          });
          if (order.OrderScheduleB1.HistoricalRecords?.length > 0) {
            order.OrderScheduleB1.HistoricalRecords.forEach((historicalRecord) => {
              if (historicalRecord.Id > newestId) {
                newestId = historicalRecord.Id;
              }
              if (historicalRecord.CreatedDateTime && String(historicalRecord.CreatedDateTime).includes("T") && !String(historicalRecord.CreatedDateTime).includes("Z")) {
                historicalRecord.CreatedDateTime = `${historicalRecord.CreatedDateTime}.000Z`;
              }
              revisions.push({
                Id: historicalRecord.Id,
                AttachmentRootId: 0,
                Type: "",
                RecordType: "scheduleb1",
                Revision: historicalRecord.Revision,
                ParentRevision: historicalRecord.ParentRevision,
                BaseVersionLabel: historicalRecord.BaseVersionLabel,
                VersionLabel: historicalRecord.VersionLabel,
                Submitted: "",
                SubmittedCheckMark: "",
                CreatedDateTime: new Date(historicalRecord.CreatedDateTime),
              });
            });
          }
          orderExaminationWorksheetRevisions.push({
            Id: newestId,
            AttachmentRootId: 0,
            Type: "Schedule-B1",
            RecordType: "scheduleb1",
            Revision: "",
            ParentRevision: "",
            CreatedDateTime: "",
            BaseVersionLabel: "",
            VersionLabel: "",
            Submitted: "",
            SubmittedCheckMark: "",
            Revisions: revisions,
          });
        }
        if (order.OrderScheduleB2 != null) {
          let revisions = [];
          let newestId = order.OrderScheduleB2.Id;
          if (
            order.OrderScheduleB2.CreatedDateTime &&
            String(order.OrderScheduleB2.CreatedDateTime).includes("T") &&
            !String(order.OrderScheduleB2.CreatedDateTime).includes("Z")
          ) {
            order.OrderScheduleB2.CreatedDateTime = `${order.OrderScheduleB2.CreatedDateTime}.000Z`;
          }
          revisions.push({
            Id: order.OrderScheduleB2.Id,
            AttachmentRootId: 0,
            Type: "",
            RecordType: "scheduleb2",
            Revision: order.OrderScheduleB2.Revision,
            ParentRevision: order.OrderScheduleB2.ParentRevision,
            BaseVersionLabel: order.OrderScheduleB2.BaseVersionLabel,
            VersionLabel: order.OrderScheduleB2.VersionLabel,
            Submitted: "",
            SubmittedCheckMark: "",
            CreatedDateTime: new Date(order.OrderScheduleB2.CreatedDateTime),
          });
          if (order.OrderScheduleB2.HistoricalRecords?.length > 0) {
            order.OrderScheduleB2.HistoricalRecords.forEach((historicalRecord) => {
              if (historicalRecord.Id > newestId) {
                newestId = historicalRecord.Id;
              }
              if (historicalRecord.CreatedDateTime && String(historicalRecord.CreatedDateTime).includes("T") && !String(historicalRecord.CreatedDateTime).includes("Z")) {
                historicalRecord.CreatedDateTime = `${historicalRecord.CreatedDateTime}.000Z`;
              }
              revisions.push({
                Id: historicalRecord.Id,
                AttachmentRootId: 0,
                Type: "",
                RecordType: "scheduleb2",
                Revision: historicalRecord.Revision,
                ParentRevision: historicalRecord.ParentRevision,
                BaseVersionLabel: historicalRecord.BaseVersionLabel,
                VersionLabel: historicalRecord.VersionLabel,
                Submitted: "",
                SubmittedCheckMark: "",
                CreatedDateTime: new Date(historicalRecord.CreatedDateTime),
              });
            });
          }
          orderExaminationWorksheetRevisions.push({
            Id: newestId,
            AttachmentRootId: 0,
            Type: "Schedule-B2",
            RecordType: "scheduleb2",
            Revision: "",
            ParentRevision: "",
            CreatedDateTime: "",
            BaseVersionLabel: "",
            VersionLabel: "",
            Submitted: "",
            SubmittedCheckMark: "",
            Revisions: revisions,
          });
        }
      }
      state.orderExaminationWorksheetRevisions = orderExaminationWorksheetRevisions;
    },
    setOrderNumbersData(state, orderNumbersData) {
      state.orderNumbers = orderNumbersData;
    },
    clearOrderNumbersData(state) {
      state.orderNumbers = [];
    },
    clearOrderExaminationWorksheetRevisions(state) {
      state.orderExaminationWorksheetRevisions = [];
    },
    setOrderWriteUpData(state, orderWriteUpData) {
      state.orderWriteUp = orderWriteUpData ?? {};
    },
    clearOrderWriteUpData(state) {
      state.orderWriteUp = {};
    },
    setSelectedOrderWriteUp(state, orderWriteUp) {
      state.selectedOrderWriteUp = orderWriteUp ?? {};
    },
    setOrderInternalWriteUpsData(state, orderInternalWriteUpsData) {
      if (orderInternalWriteUpsData?.length > 0) {
        orderInternalWriteUpsData?.forEach((orderInternalWriteUp) => {
          if (orderInternalWriteUp.CreatedDateTime && String(orderInternalWriteUp.CreatedDateTime).includes("T") && !String(orderInternalWriteUp.CreatedDateTime).includes("Z")) {
            orderInternalWriteUp.CreatedDateTime = `${orderInternalWriteUp.CreatedDateTime}.000Z`;
          }
          orderInternalWriteUp.SubmittedCheckMark = orderInternalWriteUp.ReadyToCreateCommitment ? String.fromCharCode(0x2713) : "";
          orderInternalWriteUp?.HistoricalRecords?.forEach((historicalOrderInternalWriteUp) => {
            historicalOrderInternalWriteUp.SubmittedCheckMark = historicalOrderInternalWriteUp.ReadyToCreateCommitment ? String.fromCharCode(0x2713) : "";
          });
        });
      }
      const sortedKeys = [
        GENERAL_FORM_KEY,
        SCHEDULE_B1_FORM_KEY,
        SCHEDULE_B2_FORM_KEY,
        SCHEDULE_A_POLICIES_FORM_KEY,
        SCHEDULE_A_LEGAL_DESCRIPTIONS_FORM_KEY,
        SCHEDULE_A_PIDS_FORM_KEY,
        SCHEDULE_A_ADDRESSES_FORM_KEY,
        SCHEDULE_A_EFFECTIVE_DATES_FORM_KEY,
      ];
      state.orderInternalWriteUps = (
        _.map(orderInternalWriteUpsData, (value, key) => {
          if (sortedKeys.includes(key)) {
            return _.sortBy(value, ["Position"]);
          }
          return value;
        }) ?? []
      ).sort((oiwu1, oiwu2) => (oiwu1.Revision < oiwu2.Revision ? 1 : -1));
    },
    clearOrderInternalWriteUpsData(state) {
      state.orderInternalWriteUps = [];
    },
    setOrderDS1sData(state, orderDS1sData) {
      let orderDS1s = [];
      if (orderDS1sData?.length > 0) {
        orderDS1sData.forEach((orderDS1) => {
          if (orderDS1.SignedDateTime != null) {
            orderDS1.SignedDateTime = new Date(orderDS1.SignedDateTime);
          }
          orderDS1s.push(orderDS1);
          if (orderDS1.HistoricalRecords?.length > 0) {
            orderDS1.HistoricalRecords.forEach((historicalOrderDS1) => {
              if (historicalOrderDS1.SignedDateTime != null) {
                historicalOrderDS1.SignedDateTime = new Date(historicalOrderDS1.SignedDateTime);
              }
              orderDS1s.push(historicalOrderDS1);
            });
          }
        });
      }
      state.orderDS1s = orderDS1s.sort((ods11, ods12) => (ods11.Revision < ods12.Revision ? 1 : -1));
    },
    clearOrderDS1sData(state) {
      state.orderDS1s = [];
    },
    setAuditLogs(state, auditLogs) {
      if (auditLogs?.length > 0) {
        auditLogs.forEach((auditLog) => {
          if (auditLog.LoggedDateTime != null) {
            auditLog.LoggedDateTime = new Date(auditLog.LoggedDateTime);
          }
        });
      }
      state.auditLogs = auditLogs?.sort((al1, al2) => (al1.LoggedDateTime < al2.LoggedDateTime ? 1 : -1));
    },
    setStaticTemplateData(state, staticTemplateData) {
      state.staticTemplate = staticTemplateData;
    },
    clearStaticTemplateData(state) {
      state.staticTemplate = {};
    },
    updateActiveStateOfFolderViewTypes(state, folderViewLabelType) {
      state.folderViewLabelTypes.forEach((type) => {
        if (String(folderViewLabelType) === String(type.Name)) {
          type.isActive = true;
        }
      });
    },
    clearSelectedFolderViewType(state) {
      if (state.folderViewLabelTypes?.length > 0) {
        state.folderViewLabelTypes.forEach((type) => {
          type.isActive = false;
        });
      }
    },
    setOrderReportMonthlyData(state, orderReportMonthlyData) {
      state.orderReportMonthlyData = orderReportMonthlyData ?? [];
    },
    setOrderReportBranchData(state, orderReportBranchData) {
      state.orderReportBranchData = orderReportBranchData ?? [];
    },
    setCurrentFolderLabelViewType(state, folderLabelViewType) {
      state.currentFolderLabelViewType = folderLabelViewType ?? "";
    },
    setOrdersViewType(state, orderViewType) {
      state.currentOrdersViewType = orderViewType ?? "";
    },
    setCurrentOrderSearchInput(state, searchInput) {
      state.currentOrderSearchInput = searchInput ?? "";
    },
    setCurrentSearchOrderNumber(state, searchOrderNumber) {
      state.currentSearchOrderNumber = searchOrderNumber ?? "";
    },
    setOrderTransactionTypes(state, types) {
      state.orderTransactionTypes = types?.sort((ott1, ott2) => (ott1.Name > ott2.Name ? 1 : -1));
    },
    setSelectedOrderRootId(state, rootId) {
      state.selectedOrderRootId = Number(rootId ?? 0);
    },
    setIsSearchDocumentSelected(state, isSearchDocumentSelected) {
      state.isSearchDocumentSelected = isSearchDocumentSelected;
    },
    clearAuditLog(state) {
      state.auditLogs = [];
    },
    setOrderNumberSearchData(state, orderNumberSearchData) {
      state.orderNumberSearchData = orderNumberSearchData ?? [];
    },
  },
  actions: {
    async getOrdersAdvancedSearchData(context, { SearchRootId, ProductRootId, OrganizationRootId, UserRootId, PreviewParameters, IncludeDebugData, SearchText, NoCache }) {
      return timeOperation(async () => {
        SearchRootId = Number(SearchRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchText = String(SearchText ?? "");
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${ordersUrl}?searchrootid=${SearchRootId}&productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchtext=${SearchText}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&includemetadata=true&includedebugdata=${IncludeDebugData}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: OrdersRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: OrdersRecordType, Data });
      }, "orders - getOrdersAdvancedSearchData");
    },
    async getOrderNumbersNameSearchData(context, { ProductRootId, OrganizationRootId, SearchText, IncludeExternalOrderNumbers, IncludeLinksOrderNumbers, IncludeDebugData }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        SearchText = String(SearchText ?? "");
        IncludeExternalOrderNumbers = IncludeExternalOrderNumbers ?? false;
        IncludeLinksOrderNumbers = IncludeLinksOrderNumbers ?? false;
        IncludeDebugData = IncludeDebugData ?? false;
        const formattedUrl = `${ordersUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&searchrootid=0&searchtext=${SearchText}&ordernumber=&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&includeexternalordernumbers=${IncludeExternalOrderNumbers}&includelinksordernumbers=${IncludeLinksOrderNumbers}&searchonlyordernumbers=true&returnonlyordernumbers=true&includedebugdata=${IncludeDebugData}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrderNumbersData", data);
      }, "orders - getOrderNumbersNameSearchData");
    },
    async getSingleOrderData(context, { RootId, OrderNumber, ProductRootId, IncludeDebugData, UseCache, NoCache }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        if (RootId > 0) {
          ProductRootId = Number(ProductRootId ?? 0);
          OrderNumber = String(OrderNumber ?? "");
          IncludeDebugData = IncludeDebugData ?? false;
          UseCache = UseCache ?? false;
          NoCache = NoCache ?? false;
          const formattedUrl = `${ordersUrl}/${RootId}?productrootid=${ProductRootId}&ordernumber=${OrderNumber}&timezone=${encodeURIComponent(
            getLocalTimezone()
          )}&includedebugdata=${IncludeDebugData}`;
          if (NoCache) {
            return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          }
          UseCache = UseCache ?? false;
          const cachedSingleOrderRootId = Number(context.state.cachedSingleOrderRootId ?? 0);
          const dirtyCache = cachedSingleOrderRootId !== RootId;
          if (!UseCache || dirtyCache) {
            const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
            context.commit("setCachedSingleOrderRootId", RootId);
            context.commit("setCachedSingleOrderResultsData", Data);
            context.commit("setSearchResultsData", { ViewType: OrdersRecordType, Data });
            return Data;
          } else {
            return _.cloneDeep(context.state.cachedSingleOrderResultsData) ?? {};
          }
        }
        return null;
      }, `orders - getSingleOrderData - RootId: ${RootId}, OrderNumber: ${OrderNumber}`);
    },
    async getBasicOrderData(context, { RootId, OrderNumber }) {
      return timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        OrderNumber = String(OrderNumber ?? "");
        const formattedUrl = `${ordersUrl}/${RootId}/basic?ordernumber=${OrderNumber}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setBasicOrderData", data);
        return data;
      }, "orders - getBasicOrderData");
    },
    async getOrderContactsData(context, { RootId, OrderNumber }) {
      await timeOperation(async () => {
        RootId = Number(RootId ?? 0);
        OrderNumber = String(OrderNumber ?? "");
        const formattedUrl = `${ordersUrl}/${RootId}/orderparties?ordernumber=${OrderNumber}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrderContactsData", data);
      }, "orders - getOrderContactsData");
    },
    async getOrderWriteUp(context, { ProductRootId, OrderRootId }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        const formattedUrl = `${orderWriteUpsUrl}/?productrootid=${ProductRootId}&orderrootid=${OrderRootId}&includemetadata=true&includehistory=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrderWriteUpData", data);
      }, "orders - getOrderWriteUp");
    },
    async getOrderInternalWriteUpsData(context, { OrderRootId, ProductRootId }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        if (OrderRootId > 0 && ProductRootId > 0) {
          const formattedUrl = `${orderInternalWriteUpsUrl}?orderrootid=${OrderRootId}&productrootid=${ProductRootId}&includemetadata=true&includehistory=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrderInternalWriteUpsData", data);
        } else {
          context.commit("clearOrderInternalWriteUpsData");
        }
      }, "orders - getOrderInternalWriteUpsData");
    },
    async getOrdersReportMonthlyData(context) {
      await timeOperation(async () => {
        const formattedUrl = `${reportsUrl}/orders?reporttype=${OrdersReport}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrderReportMonthlyData", data);
      }, "orders - getOrdersReportMonthlyData");
    },
    async getOrdersReportBranchData(context) {
      await timeOperation(async () => {
        const formattedUrl = `${reportsUrl}/orders?reporttype=${BranchOrdersReport}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrderReportBranchData", data);
      }, "orders - getOrdersReportBranchData");
    },
    async createNewOrder(context, placeOrder) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: placeOrdersUrl, Payload: placeOrder });
      }, "orders - createNewOrder");
    },
    async checkForPossibleDuplicateOrders(context, placeOrder) {
      return timeOperation(async () => {
        const formattedUrl = `${ordersUrl}/duplicatecheck`;
        return (await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: placeOrder })) ?? [];
      }, "orders - checkForPossibleDuplicateOrders");
    },
    async getOrderTransactionTypes(context) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: ordersTransactionTypeUrl, Payload: null });
        context.commit("setOrderTransactionTypes", data);
      }, "orders - getOrderTransactionTypes");
    },
    async orderNumberSearch(context, OrderNumber) {
      await timeOperation(async () => {
        OrderNumber = String(OrderNumber ?? "");
        const formattedUrl = `${validateNamesUrl}?typename=order&name=${OrderNumber}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrderNumberSearchData", data);
      }, "orders - orderNumberSearch");
    },
    async documentOrderNumberSearch(context, documentNamesAndOrderNumbers) {
      return timeOperation(async () => {
        const formattedUrl = `${validateNamesUrl}/validatedocumentordernumbers`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: documentNamesAndOrderNumbers });
      }, "orders - documentOrderNumberSearch");
    },
    async updateOrder(context, Order) {
      await timeOperation(async () => {
        const formattedUrl = `${ordersUrl}/${Number(Order?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: Order });
        context.commit("_updateOrder", Order);
      }, "orders - updateOrder");
    },
    async updateOrderWriteUp(context, writeUp) {
      return timeOperation(async () => {
        const localWriteUp = _.cloneDeep(writeUp);
        _.omit(localWriteUp, ["SubmittedCheckMark"]);
        const formattedUrl = `${orderWriteUpsUrl}/${Number(writeUp?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: localWriteUp });
        if (!data?.Result) {
          console.error(data?.ErrorMessage ?? "Unable To Save Write Up");
        }
        return data;
      }, "orders - updateOrderWriteUp");
    },
    async updateOrderInternalWriteUp(context, internalWriteUp) {
      await timeOperation(async () => {
        const formattedUrl = `${orderInternalWriteUpsUrl}/${Number(internalWriteUp?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: internalWriteUp });
        context.commit("setOrderInternalWriteUpsData", [data]);
      }, "orders - updateOrderInternalWriteUp");
    },
  },
};
