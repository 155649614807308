<!--suppress JSUnusedGlobalSymbols, JSUnfilteredForInLoop, JSUnresolvedVariable, JSCheckFunctionSignatures -->

<template>
  <div @dragenter.prevent @dragover.prevent @drop="handleDrop">
    <ContextMenuReplacement
      v-model:open="openContextMenu"
      v-model:close="closeContextMenu"
      :item-selected-function="contextMenuItemSelectedFunction"
      :before-open="beforeContextMenuOpenFunction"
      :target-element-top="contextMenuTargetElementTop"
      :target-element-left="contextMenuTargetElementLeft"
    />
    <div>
      <router-view />
    </div>
    <MessageOverlay
      :show="showLoadingMessage"
      @update:show="setShowLoadingMessage"
      :show-spinner="showSpinner"
      :simple-spinner="true"
      :message="loadingMessage"
      :fade-background="fadeBackground"
      :minimum-time-shown-seconds="loadingMessageMinimumTimeShownSeconds"
    />
    <FeedbackModal :show="showFeedbackModal" />
  </div>
</template>

<script>
import { computed, nextTick, watch, ref } from "vue";
import { useStore } from "vuex";

import MessageOverlay from "@/components/uIElements/MessageOverlay.vue";
import timeOperation from "@/utils/timeOperation";
import FeedbackModal from "@/components/modals/FeedbackModal.vue";
import ContextMenuReplacement from "@/components/syncfusionReplacementWrappers/ContextMenuReplacement.vue";

import {
  TrulyEsqProductRootId,
  DesqProductRootId,
  LinksProductRootId,
  OrdersTypeCanViewDebugInfoAccessKey,
  DocumentsTypeCanViewDebugInfoAccessKey,
  PlaceNewOrderFormLocationName,
} from "@/lib/settings";
export default {
  name: "app",
  components: {
    MessageOverlay,
    FeedbackModal,
    ContextMenuReplacement,
  },
  setup() {
    const store = useStore();
    //state
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    const loggedInUserRootId = computed(() => loggedInUser.value?.UserRootId || 0);
    const loggedInUserOrganizationRootId = computed(() => loggedInUser.value?.OrganizationRootId || 0);
    const showLoadingMessage = computed(() => store.state.load.showLoadingMessage);
    const loadingMessage = computed(() => store.state.load.loadingMessage);
    const loadingMessageMinimumTimeShownSeconds = computed(() => store.state.load.loadingMessageMinimumTimeShownSeconds);
    const showSpinner = computed(() => store.state.load.showSpinner);
    const fadeBackground = computed(() => store.state.load.fadeBackground);
    const cachedProductDataSourcesProductRootId = computed(() => store.state.prods.cachedProductDataSourcesProductRootId);
    const showFeedbackModal = computed(() => store.state.auth.showFeedbackModal);
    const allowFileDrop = computed(() => store.state.drop.allowFileDrop);
    const productFormLocations = computed(() => store.state.pfl.productFormLocations);
    const beforeContextMenuOpenFunction = computed(() => store.state.cm.beforeContextMenuOpenFunction);
    const contextMenuItemSelectedFunction = computed(() => store.state.cm.contextMenuItemSelectedFunction);
    const contextMenuTargetElementTop = computed(() => store.state.cm.contextMenuTargetElementTop);
    const contextMenuTargetElementLeft = computed(() => store.state.cm.contextMenuTargetElementLeft);
    //getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    const canViewDebugInfo = computed(() => {
      const productRootId = Number(getGlobalSelectedProductRootId.value || 0);
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          return getBooleanAccessSetting(OrdersTypeCanViewDebugInfoAccessKey);
        case LinksProductRootId:
          return getBooleanAccessSetting(DocumentsTypeCanViewDebugInfoAccessKey);
      }
      return false;
    });
    function getBooleanAccessSetting(settingName) {
      return Boolean(String(getUserAccessSetting.value({ ProductRootId: getGlobalSelectedProductRootId.value, SettingName: settingName, User: loggedInUser.value })) === "true");
    }
    const getUserAccessSetting = computed(() => store.getters.getUserAccessSetting);
    //mutations
    const setLoadedSearchLocations = (value) => store.commit("setLoadedSearchLocations", value);
    const setLoadedDataSources = (value) => store.commit("setLoadedDataSources", value);
    const setShowLoadingMessage = (value) => store.commit("setShowLoadingMessage", value);
    const setContextMenuOpenFunction = (value) => store.commit("setContextMenuOpenFunction", value);
    const setCloseContextMenuFunction = (value) => store.commit("setCloseContextMenuFunction", value);
    //actions
    const loadOperation = async (Operation, Message) => await store.dispatch("loadOperation", { Operation, Message, Caller: "App" });
    const getSearchOperators = async () => await store.dispatch("getSearchOperators");
    const getUserLevelFormLayouts = async () =>
      await store.dispatch("getUserLevelFormLayouts", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        UserRootId: loggedInUser.value?.UserRootId || 0,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        FormLocationRootId: 0,
        InjectReferences: false,
        IncludeDrafts: false,
        IncludeMetadata: false,
        IncludeExtendedMetadata: false,
      });
    const getSearchLocations = async () => await store.dispatch("getSearchLocations");
    const getProductDataSources = async () =>
      await store.dispatch("getProductDataSources", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        UseCache: true,
      });
    const getUsersData = async () =>
      await store.dispatch("getUsersData", {
        OrganizationRootId: loggedInUserOrganizationRootId.value || 0,
        UseCache: true,
      });
    const getOrganizationDataSources = async () =>
      await store.dispatch("getOrganizationDataSources", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        UseCache: false,
        ValidateRecords: true,
      });
    const getOrganizationGroups = async () =>
      await store.dispatch("getOrganizationGroups", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludeMetadata: false,
        IncludeMembership: false,
        IncludeAccessSettings: false,
      });
    const getOrganizationDocumentTypes = async () =>
      await store.dispatch("getOrganizationDocumentTypes", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
      });
    const getOrganizationVendorsList = async () =>
      await store.dispatch("getOrganizationVendorsList", {
        receiveOrdersFromOrganizationRootId: loggedInUserOrganizationRootId.value,
      });
    const getProductFormLocations = async () => await store.dispatch("getProductFormLocations", { ProductRootId: getGlobalSelectedProductRootId.value });
    const getProductDocumentTemplateTypes = async () => await store.dispatch("getProductDocumentTemplateTypes", { ProductRootId: getGlobalSelectedProductRootId.value });
    const getOrganizationUsers = async () =>
      await store.dispatch("getOrganizationUsers", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludeMetadata: false,
        IncludeMembership: false,
        IncludeAccessSettings: false,
        IncludeConfigSettings: false,
        IncludeOrganizationSettings: false,
        IncludeProducts: false,
        IncludeSearches: false,
        IncludeBranches: false,
        IncludeCodeBooks: false,
        IncludeAvatar: false,
        IsUsersOrganization: true,
      });
    const getDocumentTypes = async () =>
      await store.dispatch("getDocumentTypes", {
        UseCache: true,
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        UseMetadata: true,
        IncludeMetadata: true,
        IncludeDocumentTypeFields: true,
        IncludeDocumentTypeCategories: true,
        IncludeDocumentTypeTitles: true,
        IncludePartiesAndRoles: true,
      });
    const getOrganizationCustomFields = async () => {
      await store.dispatch("getOrganizationCustomFields", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        UseCache: true,
      });
    };
    const getOrganizationCustomUserFields = async () =>
      await store.dispatch("getOrganizationCustomUserFields", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        UseCache: true,
      });
    const getDataSourceTypes = async () => await store.dispatch("getDataSourceTypes");
    const placeNewOrderFormLocationRootId = computed(
      () => productFormLocations.value.find((location) => (location.ProductLocationName || "") === PlaceNewOrderFormLocationName)?.FormLocationRootId || 0
    );
    const getPlaceNewOrderFormLayout = async () =>
      await store.dispatch("getCachedFormLayoutForUserForLocation", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        FormLocationRootId: placeNewOrderFormLocationRootId.value,
        UserRootId: Number(loggedInUser.value?.UserRootId || 0),
        InjectReferences: true,
        IncludeDrafts: false,
        IncludeMetadata: true,
        IncludeExtendedMetadata: true,
        IncludeDebugData: canViewDebugInfo.value,
      });
    const getDocumentPlaceOrderReplyToSetting = async () =>
      await store.dispatch("getDocumentPlaceOrderReplyToSetting", {
        ProductRootId: getGlobalSelectedProductRootId.value,
        UserRootId: loggedInUser.value?.UserRootId || 0,
        PlacingOrganizationRootId: loggedInUserOrganizationRootId.value,
        PlacedToOrganizationRootId: 0,
        UseCache: true,
      });
    const getOrganizationVendors = async () =>
      await store.dispatch("getOrganizationVendors", {
        OrganizationRootId: loggedInUserOrganizationRootId.value,
        IncludePossible: false,
      });

    watch(
      loggedInUserRootId,
      async (is, was) => {
        await timeOperation(async () => {
          if (is === was) {
            return;
          }
          if (is > 0 && getGlobalSelectedProductRootId.value > 0) {
            await loadOperation(async () => {
              await getProductFormLocations();
              await getSearchOperators();
              await getUserLevelFormLayouts();
              await getOrganizationDataSources();
              await getOrganizationDocumentTypes();
              await getDocumentTypes();
              await getOrganizationCustomFields();
              await getOrganizationCustomUserFields();
              nextTick(async () => {
                // place new order preloading
                await getPlaceNewOrderFormLayout();
                await getDocumentPlaceOrderReplyToSetting();
              });
            }, "Loading Organization Settings");
          }
          if (is > 0) {
            setLoadedSearchLocations(false);
            await loadOperation(async () => {
              await getDataSourceTypes();
              await getSearchOperators();
              await getSearchLocations();
              setLoadedSearchLocations(true);
              await getUsersData();
              await getOrganizationGroups();
              await getOrganizationVendorsList();
              await getOrganizationVendors();
            }, "Loading Organization Data");
            await getOrganizationUsers();
          }
        }, "App - watch - loggedInUserRootId");
      },
      {
        deep: true,
      }
    );
    watch(getGlobalSelectedProductRootId, async (is) => {
      await timeOperation(async () => {
        if (cachedProductDataSourcesProductRootId.value !== is) {
          await loadOperation(async () => {
            setLoadedDataSources(false);
            await getProductDataSources();
            setLoadedDataSources(true);
            await getProductFormLocations();
          }, "Loading Product");
        }
        if (loggedInUser.value?.UserRootId > 0 && is > 0) {
          await loadOperation(async () => {
            await getUserLevelFormLayouts();
            await getOrganizationDataSources();
            await getOrganizationDocumentTypes();
            await getProductDocumentTemplateTypes();
            await getDocumentTypes();
            await getOrganizationCustomFields();
            nextTick(async () => {
              // place new order preloading
              await getPlaceNewOrderFormLayout();
              await getDocumentPlaceOrderReplyToSetting();
            });
          }, "Loading Organization Settings");
        }
      }, "App - watch - getGlobalSelectedProductRootId");
    });

    function handleDrop(event) {
      if (!allowFileDrop.value) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    const openContextMenu = ref(() => {});
    const closeContextMenu = ref(() => {});

    watch(
      closeContextMenu,
      (is) => {
        setCloseContextMenuFunction(is);
      },
      {
        deep: true,
      }
    );

    watch(
      openContextMenu,
      (is) => {
        setContextMenuOpenFunction(is);
      },
      {
        deep: true,
      }
    );

    return {
      showLoadingMessage,
      loadingMessage,
      loadingMessageMinimumTimeShownSeconds,
      showSpinner,
      fadeBackground,
      showFeedbackModal,
      openContextMenu,
      contextMenuTargetElementLeft,
      contextMenuTargetElementTop,
      beforeContextMenuOpenFunction,
      closeContextMenu,
      contextMenuItemSelectedFunction,

      setShowLoadingMessage,
      getUserLevelFormLayouts,
      getProductDataSources,
      handleDrop,
    };
  },
};
</script>

<style lang="scss">
// TODO: remove these styles eventually
@import "assets/styles/common/syncfusion-styles/material.scss";
@import "assets/styles/main";
@import "./assets/styles/stylesheet.css";
@import "../node_modules/v-tooltip/dist/v-tooltip.css";
@import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";
</style>
